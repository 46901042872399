
import Invoice from '@/components/BusinessClients/BusinessDocuments/Invoice.vue';
import { InvoiceType } from '@/models/Enums';

export default {
    components: {
        Invoice
    },
    props: {
        orderId: {
            type: String
        }
    },
    async setup() {
        const invoiceType = InvoiceType;
        return { invoiceType };
    }
};
